import config from "src/lib/config";
import DataFetcher from "src/lib/data/data-fetcher";
//#endregion
//#region Functions
export async function fetchModIndex() {
    const indexEndpoint = config.dataFolders.mods + "index.json";
    return DataFetcher.fetchJson(indexEndpoint);
}
export async function fetchModData(modId) {
    const modEndpoint = config.dataFolders.mods + modId + ".json";
    return DataFetcher.fetchJson(modEndpoint);
}
export function getModThumbnailSrc(modId) {
    return config.dataBaseUrl + config.dataFolders.thumbnails + `${modId}.png`;
}
export function getModImageSrc(modId, relSrc) {
    return config.dataBaseUrl + config.dataFolders.images + `${modId}/${relSrc}.png`;
}
export function setDefaultThumbnail() {
    const placeholder = getModThumbnailSrc("placeholder");
    if (this.src !== placeholder)
        this.src = placeholder;
}
//#endregion
