/**
 * An abstraction over localStorage.
 */
var StorageService;
(function (StorageService) {
    function clear() {
        localStorage.clear();
    }
    StorageService.clear = clear;
    function getItem(key) {
        return localStorage.getItem(key);
    }
    StorageService.getItem = getItem;
    function removeItem(key) {
        localStorage.removeItem(key);
    }
    StorageService.removeItem = removeItem;
    function setItem(key, value) {
        localStorage.setItem(key, value);
    }
    StorageService.setItem = setItem;
})(StorageService || (StorageService = {}));
export default StorageService;
