var DocumentUtils;
(function (DocumentUtils) {
    function toggleLightTheme(isLightTheme, smooth) {
        if (smooth) {
            document.documentElement.classList.add("theme-transition");
            window.setTimeout(() => {
                document.documentElement.classList.remove("theme-transition");
            }, 500); // keep in sync with theme-transition class in base.scss
        }
        if (isLightTheme) {
            document.documentElement.classList.remove("dark");
        }
        else {
            document.documentElement.classList.add("dark");
        }
    }
    DocumentUtils.toggleLightTheme = toggleLightTheme;
    function toggleBooleanAttribute(attr, value) {
        document.documentElement.setAttribute(attr, value ? "true" : "false");
    }
    DocumentUtils.toggleBooleanAttribute = toggleBooleanAttribute;
})(DocumentUtils || (DocumentUtils = {}));
export default DocumentUtils;
