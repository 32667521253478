import config from "src/lib/config";
import DataFetcher from "src/lib/data/data-fetcher";
export async function fetchToolsData() {
    return DataFetcher.fetchJson(config.dataEndpoints.tools, toolsDataProcessor);
}
function toolsDataProcessor(data) {
    data.tools.sort((a, b) => {
        if (a.pinned !== b.pinned)
            return a.pinned ? -1 : 1;
        if (a.title < b.title)
            return -1;
        if (a.title > b.title)
            return 1;
        return 0;
    });
}
